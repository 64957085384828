<!--
 * @Description: 
 * @Author: fanyunbo
 * @Date: 2022-11-11 15:10:14
 * @LastEditTime: 2024-02-02 10:57:32
 * @LastEditors: liutq
 * @Reference: 
-->
<template>
    <div :class="['setting-product', $i18n.locale]">
        <div class="header-menu">
            <span>
                <img
                    src="@/assets/images/sidebar/arrow-left.png"
                    alt
                    class="menu-info"
                    @click.prevent="onClickLeft()"
                />
            </span>
        </div>
        <p class="header-title">
            {{ $t('sidebar.changeSort') }}
        </p>
        <div class="productList-box">
            <div v-for="(item, index) in productList" :key="index" class="productList_item">
                <img class="productList-img" :src="item.imgPath" alt="" />
                <p style="productList-p">{{ item.name }}</p>
                <div class="productList-circular" @click="isCheck(index)">
                    <img v-if="proSort === index" src="@/assets/images/sidebar/checkbox-circle-fill.svg" alt="" />
                </div>
            </div>
        </div>
        <div class="describe-btn">
            <span>
                {{ $t('sidebar.describe') }}
            </span>
        </div>

        <van-dialog v-model="isConfirm" :show-confirm-button="false">
            <template slot="title"> {{ $t('sidebar.changeSort') }} </template>
            <p>{{ $t('sidebar.describeBtn') }}</p>
            <div class="btns">
                <div class="warrper1">
                    <van-button plain @click="onCancel" class="dialog-cancel">{{
                        $t('sidebar.btn.cancelBtn')
                    }}</van-button>
                </div>
                <div class="warrper2">
                    <van-button
                        @click="onConfirm"
                        class="dialog-confirm"
                        color="linear-gradient(90deg, #00E3C9 0%, #009FE8 100%)"
                        >{{ $t('sidebar.btn.confirmBtn') }}</van-button
                    >
                </div>
            </div>
        </van-dialog>
    </div>
</template>

<script>
// import Explorer from '@/assets/images/sidebar/Explorer@2x.png'
// import V_S30 from '@/assets/images/sidebar/V-S30@2x.png'
// import M_S30 from '@/assets/images/sidebar/V-M30@2x.png'
import { E_DEVICE_TYPE, E_DEVICE_NAME_TYPE, E_DEVICE_IMAGE_PATH } from '@/types/device.js'
export default {
    name: 'productModel',
    data() {
        console.log(`当前设备类型${JSON.stringify(E_DEVICE_TYPE)}`)
        const productList = []
        for (var val in E_DEVICE_TYPE) {
            const key = E_DEVICE_TYPE[val]
            productList.push({
                name: E_DEVICE_NAME_TYPE[key],
                imgPath: E_DEVICE_IMAGE_PATH[key],
                deviceType: key,
            })
        }
        return {
            productList: productList,
            isSelect: -1,
            isConfirm: false,
            proSort: JSON.parse(window.localStorage.getItem('deviceType')) - 1,
        }
    },
    created() {
        if (!window.localStorage.getItem('deviceType')) {
            window.localStorage.setItem('deviceType', 1)
            this.proSort = 1
        }
    },
    methods: {
        // 弹框控制
        isCheck(index) {
            this.isConfirm = true
            this.isSelect = index
        },
        onCancel() {
            this.isConfirm = false
        },
        // 产品类型变更
        onConfirm() {
            this.isConfirm = false
            this.proSort = this.isSelect
            console.log(`切换产品类型${this.isSelect + 1}`)
            window.localStorage.setItem('deviceType', JSON.stringify(this.isSelect + 1))
            window.localStorage.removeItem('modelInfo')
            this.$router.push({
                name: 'report',
            })
            this.$store.commit('setHistoryScanId', '')
            this.$toast({
                message: this.$t('sidebar.descReport'),
                duration: 2000,
            })
        },
        onClickLeft() {
            this.$router.push({
                name: 'report',
                params: { type: 1 },
            })
        },
    },
}
</script>

<style lang="less" scoped>
.setting-product {
    width: 100%;
    height: 100%;
    background-color: #222341;
    text-align: left;
    overflow-y: auto;
    overflow-x: hidden;

    .header-menu {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 20px 15px;
        width: 100%;
        top: 0;
        z-index: 100;

        > span {
            img {
                display: block;
            }
        }

        .menu-info {
            width: 24px;
            height: 24px;
        }
    }

    p {
        width: 100%;
        height: 22px;
        font-size: 16px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #ffffff;
        line-height: 22px;
        margin-left: 31px;
        opacity: 0.5;
    }

    .productList-box {
        width: 100%;
        height: auto;
        display: flex;
        justify-content: space-between;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: left;
        padding: 12px 16px;

        .productList_item {
            padding: 12px 0px;
            width: 50%;
        }

        .productList-img {
            width: 164px;
            height: 148px;
        }

        p {
            margin-left: 0px;
            width: 100%;
            height: 18px;
            font-size: 13px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #ffffff;
            line-height: 18px;
            text-align: center;
            margin: 12px 0;
        }

        .productList-circular {
            border: 2px #ffffff solid;
            border-radius: 50%;
            width: 22px;
            height: 22px;
            margin: auto;

            img {
                position: relative;
                top: -3px;
                left: -3px;
                width: 24px;
                height: 24px;
            }
        }
    }

    .describe-btn {
        margin: 32px 15px;
        background: rgba(48, 60, 92, 0.54);
        box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.34);
        border-radius: 4px;
        overflow: hidden;

        span {
            display: block;
            text-indent: -12px;
            font-size: 14px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #8aa3be;
            margin: 12px 22px;
            line-height: 18px;
        }
    }

    /deep/ .van-dialog {
        width: 375x;
        background-color: #ffffff;
        padding: 0 31px 18px 31px;
        font-family: PingFangSC-Regular, PingFang SC;
        text-align: center;

        .van-dialog__header {
            font-size: 17px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #000000;
            line-height: 24px;
            opacity: 1;
        }

        .van-dialog__content {
            p {
                height: auto;
                margin: 12px 0;
                font-size: 13px;
                font-family: PingFangSC-Regular, PingFang SC;
                font-weight: 400;
                color: #111;
                line-height: 20px;
                opacity: 1;
            }
        }

        .btns {
            display: flex;
            justify-content: center;
            align-items: center;
            overflow: hidden;

            .dialog-cancel {
                position: relative;
                width: 112px;
                height: 32px;
                border-radius: 5px !important;
                // border: 1px solid;
                color: #000;
                background-color: #ffffff;
                margin-right: 15px;

                &::after {
                    position: absolute;
                    top: -2px;
                    bottom: -2px;
                    left: -2px;
                    right: -2px;
                    background: linear-gradient(90deg, rgba(0, 227, 201, 1), rgba(0, 159, 232, 1));
                    content: '';
                    z-index: -1;
                    border-radius: 5px;
                }
            }

            .dialog-confirm {
                color: #000;
                width: 112px;
                height: 34px;
                background: linear-gradient(90deg, #00e3c9 0%, #009fe8 100%);
                border-radius: 5px !important;
                border: 1px solid transparent;

                .van-button__text {
                    color: #222222;
                    font-size: 14px;
                    font-family: PingFangSC-Regular, PingFang SC;
                    font-weight: 400;
                }

                // border-image: linear-gradient(90deg, rgba(0, 227, 201, 1), rgba(0, 159, 232, 1)) 1 1;
            }
        }
    }
}
&.ar-AR {
    direction: rtl;
    .productList-circular {
        img {
            left: 3px !important;
        }
    }

    
    .dialog-confirm,.dialog-cancel{
        width: 100px !important;
    }
    .dialog-cancel{
        margin-left: 16px;
        margin-right: 0 !important;
    }
    .van-dialog .van-dialog__content p{
        text-align: right;
    }
    .header-title{
        text-align: right;
        margin-right: 22px;
    }
    .productList-box{
        justify-content: right;
    }
}
</style>
<style>
.van-cell__value {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}
</style>
